import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

interface Snowflake {
  id: number;
  x: number;
  y: number;
  size: number;
  speed: number;
  opacity: number;
}

export function Snow() {
  const [snowflakes, setSnowflakes] = useState<Snowflake[]>([]);
  const { isSnowEnabled } = useTheme();
  const location = useLocation();

  // Only show snow on home page and when enabled
  const shouldShowSnow = location.pathname === '/' && isSnowEnabled;

  useEffect(() => {
    if (!shouldShowSnow) {
      setSnowflakes([]);
      return;
    }

    // Create initial snowflakes
    const initialSnowflakes = Array.from({ length: 50 }, (_, i) => ({
      id: i,
      x: Math.random() * 100, // percentage across screen
      y: Math.random() * 100, // percentage down screen
      size: Math.random() * 4 + 2, // pixels
      speed: Math.random() * 1 + 0.5, // percentage per second
      opacity: Math.random() * 0.6 + 0.4,
    }));

    setSnowflakes(initialSnowflakes);

    // Animation loop
    let animationFrameId: number;
    let lastTime = performance.now();

    const animate = (currentTime: number) => {
      const deltaTime = (currentTime - lastTime) / 1000; // convert to seconds
      lastTime = currentTime;

      setSnowflakes(prevSnowflakes =>
        prevSnowflakes.map(flake => ({
          ...flake,
          y: (flake.y + flake.speed * deltaTime * 50) % 100, // Wrap around when reaching bottom
          x: flake.x + (Math.sin(currentTime / 1000 + flake.id) * deltaTime * 20), // Gentle side-to-side motion
        }))
      );

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [shouldShowSnow]);

  if (!shouldShowSnow) {
    return null;
  }

  return (
    <div className="fixed inset-0 pointer-events-none">
      {snowflakes.map(flake => (
        <div
          key={flake.id}
          className="absolute rounded-full bg-white"
          style={{
            left: `${flake.x}%`,
            top: `${flake.y}%`,
            width: `${flake.size}px`,
            height: `${flake.size}px`,
            opacity: flake.opacity,
            filter: 'blur(1px)',
            transform: `translate(-50%, -50%)`,
            transition: 'opacity 0.2s ease-in-out',
          }}
        />
      ))}
    </div>
  );
} 