import { Gift, Sparkle, TreePine } from 'lucide-react';
import { CheckoutButton } from './pricing/CheckoutButton';
import { STRIPE_PRICE_IDS } from '../lib/constants';

export function SpecialChristmasOffer() {
  return (
    <div className="relative overflow-hidden bg-gradient-to-r from-red-600 to-red-700 py-16">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-8 -left-8 w-64 h-64 bg-red-500 rounded-full mix-blend-multiply filter blur-3xl opacity-70" />
        <div className="absolute -bottom-8 -right-8 w-64 h-64 bg-red-800 rounded-full mix-blend-multiply filter blur-3xl opacity-70" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          {/* Content */}
          <div className="mb-12 lg:mb-0 text-center lg:text-left">
            <div className="inline-flex items-center gap-2 bg-red-800/30 text-red-100 px-4 py-2 rounded-full text-sm font-medium mb-4">
              <Gift className="w-4 h-4" />
              Limited Time Offer
            </div>
            <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
              Get Premium Gallery Access
            </h2>
            <p className="text-red-100 text-lg mb-8">
              Unlock our exclusive collection of premium Christmas wallpapers and AI-generated designs.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
              <CheckoutButton
                priceId={STRIPE_PRICE_IDS.PREMIUM}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-red-600 bg-white hover:bg-red-50 transition-colors"
              >
                Get Premium Access
              </CheckoutButton>
              <button className="inline-flex items-center px-6 py-3 border-2 border-red-200 text-base font-medium rounded-lg text-white hover:bg-red-800/20 transition-colors">
                Learn More
              </button>
            </div>
          </div>

          {/* Feature list */}
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8">
            <h3 className="text-xl font-semibold text-white mb-6 flex items-center gap-2">
              <TreePine className="w-5 h-5" />
              Premium Features
            </h3>
            <ul className="space-y-4">
              {[
                'Access 1000+ premium wallpapers',
                'New designs added daily',
                'Priority customer support',
                'Commercial usage rights',
                'Early access to new features',
              ].map((feature, index) => (
                <li key={index} className="flex items-center gap-3 text-red-100">
                  <Sparkle className="w-5 h-5 text-yellow-400" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
} 