import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { generateImage } from '../lib/generations';
import { Button } from './ui/Button';
import { Textarea } from './ui/Textarea';
import { useAuth } from '../contexts/AuthContext';
import { useGenerations } from '../contexts/GenerationsContext';
import { toast } from 'react-hot-toast';
import { Wand2, Gift, Sparkles, AlertCircle, Shuffle, Monitor, Smartphone, Laptop } from 'lucide-react';
import { getRandomPrompt } from '../lib/christmasPrompts';

type DeviceSize = {
  name: string;
  width: number;
  height: number;
  icon: React.ReactNode;
};

const deviceSizes: DeviceSize[] = [
  { name: 'iPhone', width: 1168, height: 2536, icon: <Smartphone className="w-5 h-5" /> },
  { name: 'Android', width: 1440, height: 2960, icon: <Smartphone className="w-5 h-5" /> },
  { name: 'MacBook', width: 2560, height: 1600, icon: <Laptop className="w-5 h-5" /> },
  { name: 'Chromebook', width: 1920, height: 1080, icon: <Monitor className="w-5 h-5" /> },
];

export function GenerationForm() {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageCount, setImageCount] = useState(1);
  const [selectedDevice, setSelectedDevice] = useState<DeviceSize>(deviceSizes[0]);
  const { user, profile } = useAuth();
  const { remainingGenerations, decrementGenerations, refreshGenerations } = useGenerations();
  const navigate = useNavigate();

  const canGenerate = profile?.is_premium || (remainingGenerations > 0);

  const handleRandomPrompt = () => {
    setPrompt(getRandomPrompt());
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    if (!user || !profile) {
      setError('Please sign in to generate images');
      return;
    }

    if (!canGenerate) {
      setError('No generations remaining. Please upgrade to premium.');
      return;
    }

    if (!prompt.trim()) {
      setError('Please enter a description');
      return;
    }

    if (imageCount > remainingGenerations && !profile.is_premium) {
      setError(`You only have ${remainingGenerations} generations remaining`);
      return;
    }

    setLoading(true);
    const loadingToast = toast.loading('Generating your Christmas scene...');
    
    try {
      const promises = Array(imageCount).fill(0).map(async (_, index) => {
        const currentPromise = generateImage({
          prompt: prompt.trim(),
          width: selectedDevice.width,
          height: selectedDevice.height,
          isPremium: !!profile.is_premium
        });

        // Update loading message for multiple images
        if (imageCount > 1) {
          toast.loading(`Generating image ${index + 1} of ${imageCount}...`, 
            { id: loadingToast });
        }

        return currentPromise;
      });

      await Promise.all(promises);
      
      // Decrement generations after successful generation
      if (!profile.is_premium) {
        await decrementGenerations(imageCount);
      }
      
      setPrompt('');
      await refreshGenerations(); // Refresh the generation count
      
      toast.success(`${imageCount} image${imageCount > 1 ? 's' : ''} generated successfully!`, 
        { id: loadingToast });
      navigate('/gallery');
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to generate image';
      setError(errorMessage);
      toast.error(errorMessage, { id: loadingToast });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex items-center gap-2 bg-red-50 text-red-700 px-4 py-3 rounded-lg">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="bg-gray-50 rounded-lg p-4">
        {profile?.is_premium ? (
          <div className="flex items-center gap-3">
            <div className="p-2 bg-purple-100 rounded-lg">
              <Sparkles className="w-5 h-5 text-purple-500" />
            </div>
            <div>
              <p className="font-medium text-gray-900">Premium Member</p>
              <p className="text-sm text-gray-600">30 generations per month with priority processing</p>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-100 rounded-lg">
                <Gift className="w-5 h-5 text-blue-500" />
              </div>
              <div>
                <p className="font-medium text-gray-900">Generation Credits</p>
                <p className="text-sm text-gray-600">{remainingGenerations} generations remaining</p>
              </div>
            </div>
            {remainingGenerations === 0 && (
              <Button
                variant="premium"
                onClick={() => navigate('/pricing')}
                className="flex items-center gap-2"
              >
                <Gift className="w-4 h-4" />
                Get More Credits
              </Button>
            )}
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="relative"
        >
          <Textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Describe your perfect Christmas scene... (e.g., A cozy cabin in a snowy forest with warm lights glowing through the windows)"
            className="min-h-[120px] text-lg p-4 rounded-xl border-2 border-gray-200 focus:border-blue-500 focus:ring-blue-500 transition-colors pr-12"
            disabled={loading || !canGenerate}
          />
          <Button
            type="button"
            variant="outline"
            onClick={handleRandomPrompt}
            className="absolute right-2 top-2 p-2 hover:bg-gray-100 transition-colors"
            disabled={loading}
          >
            <Shuffle className="w-5 h-5 text-gray-500 hover:text-blue-500 transition-colors" />
          </Button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="grid grid-cols-1 sm:grid-cols-2 gap-4"
        >
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Device Size</label>
            <div className="grid grid-cols-2 gap-2">
              {deviceSizes.map((device) => (
                <Button
                  key={device.name}
                  type="button"
                  variant={selectedDevice.name === device.name ? 'default' : 'outline'}
                  onClick={() => setSelectedDevice(device)}
                  className="flex items-center gap-2 justify-center"
                  disabled={loading}
                >
                  {device.icon}
                  {device.name}
                </Button>
              ))}
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Number of Images</label>
            <div className="grid grid-cols-3 gap-2">
              {[1, 2, 3].map((num) => (
                <Button
                  key={num}
                  type="button"
                  variant={imageCount === num ? 'default' : 'outline'}
                  onClick={() => setImageCount(num)}
                  className="flex items-center justify-center"
                  disabled={loading || (!profile?.is_premium && num > remainingGenerations)}
                >
                  {num}
                </Button>
              ))}
            </div>
          </div>
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="flex flex-col sm:flex-row gap-4 items-center justify-between"
        >
          <Button 
            type="submit" 
            disabled={loading || !prompt.trim() || !canGenerate}
            className="w-full sm:w-auto min-w-[200px] h-12 text-lg relative overflow-hidden group"
          >
            <span className="relative z-10 flex items-center justify-center gap-2">
              <Wand2 className="w-5 h-5" />
              {loading ? 'Generating...' : `Generate ${imageCount} Image${imageCount > 1 ? 's' : ''}`}
            </span>
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600"
              initial={false}
              animate={{
                x: loading ? '100%' : '0%',
              }}
              transition={{ duration: 0.5 }}
            />
          </Button>
        </motion.div>
      </form>

      {loading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center text-sm text-gray-500 mt-4"
        >
          <p>Creating your masterpiece{imageCount > 1 ? 's' : ''}... This may take up to {30 * imageCount} seconds.</p>
        </motion.div>
      )}
    </div>
  );
}