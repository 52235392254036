import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { Loader2 } from 'lucide-react';
import { StripePriceId } from '../../lib/constants';

interface CheckoutButtonProps {
  priceId: StripePriceId;
  className?: string;
  children: React.ReactNode;
}

export function CheckoutButton({ priceId, className = '', children }: CheckoutButtonProps) {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!user) {
      navigate(`/login?redirect=/pricing&priceId=${priceId}`);
      return;
    }

    try {
      setLoading(true);
      console.log('Starting checkout for:', { priceId, userId: user.id });

      const response = await fetch('/api/create-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          userId: user.id,
          email: user.email,
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/pricing`,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      if (!url) {
        throw new Error('No checkout URL returned');
      }

      console.log('Redirecting to checkout:', url);
      window.location.href = url;
    } catch (err) {
      console.error('Checkout error:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to start checkout. Please try again.';
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={loading}
      className={`relative ${className}`}
    >
      {loading ? (
        <>
          <Loader2 className="w-4 h-4 animate-spin mx-auto" />
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        children
      )}
    </button>
  );
}