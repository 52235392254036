import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Header } from '../components/Header';
import { Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';

export default function Success() {
  const navigate = useNavigate();
  const { user, refreshProfile } = useAuth();

  useEffect(() => {
    async function refreshData() {
      if (!user) {
        navigate('/');
        return;
      }

      try {
        // Add a delay to ensure webhook has processed
        await new Promise(resolve => setTimeout(resolve, 5000));

        // Refresh the profile data
        await refreshProfile();
        
        // Show success toast
        toast.success('Purchase successful! Your generations have been added.');
        
        // Redirect to generate page
        window.location.href = '/generate';
      } catch (error) {
        console.error('Error refreshing profile:', error);
        // Still redirect to generate page, but show an error toast
        toast.error('There was an issue updating your profile. Please contact support if the issue persists.');
        setTimeout(() => {
          window.location.href = '/generate';
        }, 2000);
      }
    }

    refreshData();
  }, [user, navigate, refreshProfile]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
          <h1 className="text-xl font-semibold text-gray-900">Processing your purchase...</h1>
          <p className="text-gray-600">Please wait while we update your account.</p>
        </div>
      </main>
    </div>
  );
}
