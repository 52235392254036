export const christmasPrompts = [
  "A cozy log cabin with twinkling Christmas lights in a snowy forest at dusk",
  "A magical Christmas market with wooden stalls and floating lanterns in a snow-covered town square",
  "A vintage red truck carrying a freshly cut Christmas tree down a snowy country road",
  "A festive Christmas living room with a crackling fireplace and stockings hung with care",
  "An enchanted winter forest with snow-covered pine trees and glowing fairy lights",
  "A charming gingerbread house with candy cane decorations in a whimsical winter wonderland",
  "A peaceful winter village with smoke rising from chimneys and wreaths on every door",
  "Santa's workshop at the North Pole with busy elves and magical Christmas decorations",
  "A snow globe scene featuring a Victorian Christmas street with horse-drawn carriages",
  "A festive Christmas window display with animated toys and sparkling decorations",
  "A winter palace with ice sculptures and Christmas trees lining the entrance",
  "A cozy Christmas cafe with steaming hot chocolate and freshly baked cookies",
  "A magical reindeer stable under the Northern Lights on Christmas Eve",
  "A traditional Christmas dinner scene with all the trimmings and candlelight",
  "An ice skating rink surrounded by Christmas trees and twinkling lights"
];

export const getRandomPrompt = () => {
  return christmasPrompts[Math.floor(Math.random() * christmasPrompts.length)];
};
