import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { GeneratedImage } from '../types/database';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';
import { ImageModal } from '../components/gallery/ImageModal';
import { Filter, Search, Smartphone, Monitor, Image as ImageIcon, Sparkles } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

type FilterType = 'all' | 'landscape' | 'portrait' | 'cozy' | 'modern';
type GalleryType = 'personal' | 'premium';

export const Gallery: React.FC = () => {
  const [images, setImages] = useState<GeneratedImage[]>([]);
  const [premiumImages, setPremiumImages] = useState<GeneratedImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<GeneratedImage | null>(null);
  const [activeFilter, setActiveFilter] = useState<FilterType>('all');
  const [activeGallery, setActiveGallery] = useState<GalleryType>('personal');
  const [searchQuery, setSearchQuery] = useState('');
  const { user, profile, refreshProfile } = useAuth();

  const filters: { id: FilterType; label: string; icon: React.ReactNode }[] = [
    { id: 'all', label: 'All Images', icon: <ImageIcon className="w-4 h-4" /> },
    { id: 'landscape', label: 'Landscape', icon: <Monitor className="w-4 h-4" /> },
    { id: 'portrait', label: 'Portrait', icon: <Smartphone className="w-4 h-4" /> },
    { id: 'cozy', label: 'Cozy', icon: null },
    { id: 'modern', label: 'Modern', icon: null }
  ];

  useEffect(() => {
    const loadGallery = async () => {
      try {
        setLoading(true);
        await fetchImages();
        if (profile?.is_premium) {
          await fetchPremiumImages();
        }
      } catch (error) {
        console.error('Error loading gallery:', error);
        toast.error('Failed to load gallery');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      loadGallery();
    } else {
      setLoading(false);
    }
  }, [user, profile?.is_premium]);

  // Add manual refresh function
  const refreshGallery = async () => {
    try {
      setLoading(true);
      
      // First refresh the profile
      await refreshProfile();
      
      // Then fetch images sequentially
      await fetchImages();
      
      // Only fetch premium images if the user is premium
      if (profile?.is_premium) {
        await fetchPremiumImages();
      }
      
      toast.success('Gallery refreshed successfully');
    } catch (error: any) {
      console.error('Error refreshing gallery:', error);
      toast.error(error.message || 'Failed to refresh gallery');
    } finally {
      setLoading(false);
    }
  };

  const fetchImages = async () => {
    try {
      if (!user) return;

      const { data, error } = await supabase
        .from('generated_images')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching images:', error);
        throw error;
      }

      if (data) {
        // Filter out any entries without valid URLs
        const validImages = data.filter(img => img.url && img.url.trim() !== '');
        setImages(validImages);
      } else {
        setImages([]);
      }
    } catch (error: any) {
      console.error('Error fetching images:', error);
      throw new Error('Failed to load images');
    }
  };

  const fetchPremiumImages = async () => {
    try {
      if (!user || !profile?.is_premium) {
        setPremiumImages([]);
        return;
      }

      const { data, error } = await supabase
        .from('premium_gallery')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching premium images:', error);
        throw error;
      }

      if (data) {
        const validImages = data.filter(img => img.url && img.url.trim() !== '');
        setPremiumImages(validImages);
      } else {
        setPremiumImages([]);
      }
    } catch (error: any) {
      console.error('Error fetching premium images:', error);
      throw new Error('Failed to load premium images');
    }
  };

  const getDeviceType = (width: number, height: number) => {
    if (!width || !height) return 'other';
    const aspectRatio = width / height;
    if (aspectRatio < 0.75) return 'portrait';
    if (aspectRatio > 1.33) return 'landscape';
    return 'other';
  };

  const filteredImages = (activeGallery === 'premium' ? premiumImages : images).filter(image => {
    if (!image) return false;

    let matchesFilter = true;

    switch (activeFilter) {
      case 'all':
        matchesFilter = true;
        break;
      case 'landscape':
      case 'portrait':
      case 'cozy':
      case 'modern':
        matchesFilter = image.categories?.includes(activeFilter) ?? false;
        break;
      default:
        matchesFilter = true;
    }

    const matchesSearch = !searchQuery || image.prompt.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
        {/* Gallery Header */}
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 tracking-tight">Your Gallery</h1>
          <div className="mt-2 flex flex-col sm:flex-row sm:items-center gap-4">
            <p className="text-lg text-gray-600">
              Explore your collection of AI-generated Christmas artworks
            </p>
            {profile && (
              <div className="flex items-center gap-4 text-sm">
                {profile.is_premium && (
                  <span className="px-3 py-1 bg-yellow-50 text-yellow-600 rounded-full flex items-center gap-2">
                    <Sparkles className="w-4 h-4" />
                    Premium
                  </span>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Gallery Type Selector */}
        {profile?.is_premium && (
          <div className="mb-8">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-wrap gap-4">
                <button
                  onClick={() => setActiveGallery('personal')}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300
                    ${activeGallery === 'personal'
                      ? 'bg-red-100 text-red-600 ring-2 ring-red-500 ring-offset-2'
                      : 'bg-white text-gray-600 hover:bg-gray-50'
                    }`}
                >
                  Personal Gallery
                </button>
                <button
                  onClick={() => setActiveGallery('premium')}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300 flex items-center space-x-2
                    ${activeGallery === 'premium'
                      ? 'bg-red-100 text-red-600 ring-2 ring-red-500 ring-offset-2'
                      : 'bg-white text-gray-600 hover:bg-gray-50'
                    }`}
                >
                  <Sparkles className="w-4 h-4" />
                  <span>Premium Gallery</span>
                </button>
                <button
                  onClick={async () => {
                    try {
                      await refreshGallery();
                      toast.success('Gallery refreshed successfully');
                    } catch (error) {
                      console.error('Error refreshing gallery:', error);
                      toast.error('Failed to refresh gallery');
                    }
                  }}
                  className="px-4 py-2 rounded-lg text-sm font-medium bg-white text-gray-600 hover:bg-gray-50 transition-all duration-300 flex items-center gap-2"
                >
                  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span>Refresh Gallery</span>
                </button>
              </div>
              {activeGallery === 'premium' && profile && (
                <div className="text-sm text-gray-600 flex flex-wrap gap-4">
                  {profile.premium_since && (
                    <span>Premium since: {new Date(profile.premium_since).toLocaleDateString()}</span>
                  )}
                  {profile.current_plan && (
                    <span className="capitalize">Plan: {profile.current_plan}</span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Search and Filters */}
        <div className="mb-8 space-y-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search by prompt..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500"
            />
          </div>

          <div className="flex items-center gap-2 mb-4">
            <Filter className="w-5 h-5 text-gray-500" />
            <span className="font-medium text-gray-700">Filter by Type</span>
          </div>
          <div className="flex flex-wrap gap-2">
            {filters.map((filter) => (
              <button
                key={filter.id}
                onClick={() => setActiveFilter(filter.id)}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300 flex items-center gap-2
                  ${activeFilter === filter.id
                    ? 'bg-red-100 text-red-600 ring-2 ring-red-500 ring-offset-2'
                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900 shadow-sm'
                  }`}
              >
                {filter.icon}
                {filter.label}
              </button>
            ))}
          </div>
        </div>

        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading your masterpieces...</p>
          </div>
        ) : filteredImages.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <p className="text-gray-600">No images found. Try adjusting your filters or creating new images!</p>
          </div>
        ) : (
          <motion.div 
            layout
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 auto-rows-max"
          >
            <AnimatePresence>
              {filteredImages.map((image, index) => (
                <motion.div
                  key={image.id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="relative group cursor-pointer overflow-hidden rounded-xl bg-white shadow-sm hover:shadow-xl transition-all duration-300"
                  onClick={() => setSelectedImage(image)}
                >
                  <div className="aspect-[16/9] w-full relative overflow-hidden">
                    <img
                      src={image.url}
                      alt={image.prompt}
                      className="absolute inset-0 w-full h-full object-cover transition-all duration-500 group-hover:scale-110"
                      loading="lazy"
                      onError={(e) => {
                        e.currentTarget.src = 'https://via.placeholder.com/1920x1080?text=Image+Failed+to+Load';
                      }}
                    />
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300">
                    <div className="absolute inset-x-0 bottom-0 p-4 transform translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
                      <p className="text-white text-sm font-medium line-clamp-2 mb-1">{image.prompt}</p>
                      <div className="flex items-center justify-between text-white/80 text-xs">
                        <span>
                          {new Date(image.created_at).toLocaleDateString(undefined, {
                            month: 'short',
                            day: 'numeric'
                          })}
                        </span>
                        <span className="px-2 py-1 bg-white/20 rounded-full">
                          {getDeviceType(image.width || 0, image.height || 0)}
                        </span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}

        {selectedImage && (
          <ImageModal
            image={selectedImage}
            onClose={() => setSelectedImage(null)}
          />
        )}
      </main>
    </div>
  );
};