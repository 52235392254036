import { PlanType } from '../types/database';

export type StripePriceId = string;

export const STRIPE_PRICE_IDS = {
  STARTER: process.env.VITE_STRIPE_PRICE_STARTER || 'price_1QTxlxQMKtFTp2jFTS5LQSDQ',
  HOLIDAY: process.env.VITE_STRIPE_PRICE_HOLIDAY || 'price_1QTxn3QMKtFTp2jFRhkcgoSN',
  PREMIUM: process.env.VITE_STRIPE_PRICE_PREMIUM || 'price_1QTxpbQMKtFTp2jFLcw0hCp9',
} as const;

export interface PriceConfig {
  amount: number;
  generations: number;
  name: string;
  isPremiumGallery?: boolean;
}

export const PRICES: Record<keyof typeof STRIPE_PRICE_IDS, PriceConfig> = {
  STARTER: {
    amount: 5,
    generations: 10,
    name: 'Starter'
  },
  HOLIDAY: {
    amount: 10,
    generations: 25,
    name: 'Holiday Bundle'
  },
  PREMIUM: {
    amount: 14.99,
    generations: 15,
    name: 'Premium Gallery',
    isPremiumGallery: true
  }
};

export const PLANS: Record<PlanType, {
  name: string;
  price: number;
  generations?: number;
  features: string[];
  priceId: StripePriceId;
}> = {
  free: {
    name: 'Free',
    price: 0,
    generations: 5,
    features: ['5 free generations', 'Basic wallpapers', 'Standard support'],
    priceId: '' as StripePriceId,
  },
  starter: {
    name: PRICES.STARTER.name,
    price: PRICES.STARTER.amount,
    generations: PRICES.STARTER.generations,
    features: [
      '10 generations',
      'HD wallpapers',
      'Priority support',
    ],
    priceId: STRIPE_PRICE_IDS.STARTER,
  },
  holiday: {
    name: PRICES.HOLIDAY.name,
    price: PRICES.HOLIDAY.amount,
    generations: PRICES.HOLIDAY.generations,
    features: [
      '25 generations',
      'HD wallpapers',
      'Priority support',
      'Holiday themed templates'
    ],
    priceId: STRIPE_PRICE_IDS.HOLIDAY,
  },
  premium: {
    name: PRICES.PREMIUM.name,
    price: PRICES.PREMIUM.amount,
    generations: PRICES.PREMIUM.generations,
    features: [
      '15 generations',
      '1000+ premium wallpapers',
      'Priority support',
      'Premium gallery access',
      'Custom resolutions'
    ],
    priceId: STRIPE_PRICE_IDS.PREMIUM,
  }
}; 