import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Header } from '../components/Header';
import { Snow } from '../components/Snow';
import { SpecialChristmasOffer } from '../components/SpecialChristmasOffer';
import { Sparkles, Paintbrush, ArrowDownToLine, Twitter, Instagram, Github } from 'lucide-react';
import christmasBg from '../assets/christmas-bg.jpg';
import { Link } from 'react-router-dom';

export function Home() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleGetStarted = () => {
    if (user) {
      navigate('/generate');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Snow />
      <Header />
      
      <main className="flex-grow">
        {/* Hero Section */}
        <div 
          className="relative isolate min-h-[80vh] flex items-center"
          style={{
            backgroundImage: `url(${christmasBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* Overlay gradient for better text readability */}
          <div className="absolute inset-0 bg-gradient-to-r from-black/60 via-black/40 to-black/60 backdrop-blur-[2px]" />
          
          <div className="relative mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl drop-shadow-lg">
                Create Magical Christmas Wallpapers with AI
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-100 drop-shadow">
                Design stunning, personalized Christmas wallpapers in seconds using artificial intelligence. Perfect for decorating your devices during the holiday season.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <button
                  onClick={handleGetStarted}
                  className="rounded-md bg-red-600 px-6 py-3 text-lg font-semibold text-white shadow-lg hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all hover:scale-105"
                >
                  Get Started
                </button>
                <button
                  onClick={() => navigate('/gallery')}
                  className="text-lg font-semibold leading-6 text-white hover:text-gray-200 transition-colors drop-shadow-lg"
                >
                  View Gallery <span aria-hidden="true">→</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Special Offer */}
        <SpecialChristmasOffer />

        {/* Features Section */}
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-red-600">Create Faster</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Everything you need to create perfect Christmas wallpapers
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our AI-powered platform makes it easy to create stunning Christmas wallpapers in minutes, not hours.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {features.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                      <feature.icon className="h-5 w-5 flex-none text-red-600" aria-hidden="true" />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p className="flex-auto">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            <a href="https://twitter.com" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">Twitter</span>
              <Twitter className="h-6 w-6" />
            </a>
            <a href="https://instagram.com" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">Instagram</span>
              <Instagram className="h-6 w-6" />
            </a>
            <a href="https://github.com" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">GitHub</span>
              <Github className="h-6 w-6" />
            </a>
          </div>
          <div className="mt-8 md:order-1 md:mt-0">
            <nav className="flex justify-center space-x-6">
              <Link to="/privacy" className="text-gray-400 hover:text-gray-300 text-sm">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-gray-400 hover:text-gray-300 text-sm">
                Terms of Service
              </Link>
            </nav>
            <p className="mt-4 text-center text-xs leading-5 text-gray-400">
              &copy; {new Date().getFullYear()} ChristmasAI.art. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

const features = [
  {
    name: 'AI-Powered Generation',
    description: 'Advanced AI technology creates unique and beautiful Christmas wallpaper designs based on your descriptions.',
    icon: Sparkles,
  },
  {
    name: 'Multiple Styles',
    description: 'Choose from various artistic styles to match your vision, from traditional to modern holiday designs.',
    icon: Paintbrush,
  },
  {
    name: 'Instant Downloads',
    description: 'Download your wallpapers instantly in high resolution, perfect for any device or screen size.',
    icon: ArrowDownToLine,
  },
] as const;