import { Check } from 'lucide-react';
import { CheckoutButton } from './CheckoutButton';
import { StripePriceId } from '../../lib/constants';

interface PricingFeature {
  text: string;
  included: boolean;
}

interface PricingPlan {
  name: string;
  description: string;
  price: number;
  stripePriceId: StripePriceId;
  features: PricingFeature[];
}

interface PricingCardProps {
  plan: PricingPlan;
  isPopular?: boolean;
}

export function PricingCard({ plan, isPopular = false }: PricingCardProps) {
  return (
    <div 
      className={`relative rounded-2xl ${
        isPopular 
          ? 'border-2 border-blue-500 shadow-xl' 
          : 'border border-gray-200 shadow-lg'
      } bg-white p-8`}
    >
      {isPopular && (
        <div className="absolute -top-5 left-1/2 -translate-x-1/2">
          <div className="inline-block rounded-full bg-gradient-to-r from-blue-500 to-blue-600 px-4 py-1 text-sm font-semibold text-white shadow-sm">
            Most Popular
          </div>
        </div>
      )}

      <div className="text-center">
        <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
        <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
        <div className="mt-4 flex items-baseline justify-center gap-x-2">
          <span className="text-4xl font-bold tracking-tight text-gray-900">
            ${plan.price}
          </span>
          {plan.price < 15 && (
            <span className="text-sm font-semibold text-gray-600">one-time</span>
          )}
        </div>
      </div>

      <ul className="mt-8 space-y-4">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <div className="flex-shrink-0">
              <Check 
                className={`h-6 w-6 ${
                  feature.included ? 'text-blue-500' : 'text-gray-300'
                }`}
              />
            </div>
            <span className={`ml-3 text-sm ${
              feature.included ? 'text-gray-700' : 'text-gray-400'
            }`}>
              {feature.text}
            </span>
          </li>
        ))}
      </ul>

      <div className="mt-8">
        <CheckoutButton
          priceId={plan.stripePriceId}
          className={`w-full py-3 px-4 rounded-lg text-sm font-semibold shadow-sm transition-colors ${
            isPopular
              ? 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500'
              : 'bg-gray-100 text-gray-900 hover:bg-gray-200 focus:ring-gray-500'
          } focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          Get Started
        </CheckoutButton>
      </div>
    </div>
  );
}