import { supabase } from './supabase';

interface GenerateImageParams {
  prompt: string;
  isPremium: boolean;
  width?: number;
  height?: number;
}

export const randomPrompts = [
  // Cozy Indoor Scenes
  "A luxurious modern penthouse living room decorated for Christmas, floor-to-ceiling windows overlooking a snowy cityscape, white Christmas tree with gold ornaments, soft ambient lighting",
  "Cozy rustic cabin interior with a roaring fireplace, vintage Christmas decorations, stockings hung with care, warm lighting casting shadows on wooden walls",
  "Elegant Victorian parlor decorated for Christmas, crystal chandelier, ornate Christmas tree with vintage ornaments, velvet curtains, soft candlelight",
  
  // Outdoor Nature Scenes
  "Enchanted winter forest path lined with snow-covered pine trees, warm lantern light, gentle snowfall, Christmas lights twinkling in the distance",
  "Majestic mountain lodge at twilight, snow-covered peaks in background, warm lights glowing from windows, decorated Christmas trees outside",
  "Charming European Christmas market square at dusk, cobblestone streets, wooden stalls with twinkling lights, light snow falling, warm golden glow",
  
  // Modern & Minimalist
  "Contemporary Scandinavian living room at Christmas, minimalist white decor, geometric Christmas tree, large windows showing snowy landscape",
  "Modern glass house in snowy forest, architectural lighting, sleek Christmas decorations, floor-to-ceiling windows reflecting winter sunset",
  "Urban loft with industrial elements, modern Christmas decor, city lights visible through windows, minimal color palette with touches of red",
  
  // Whimsical & Magical
  "Magical Christmas village with colorful cottages, snow-covered roofs, warm glowing windows, tiny bridges and frozen streams, evening sky",
  "Enchanted greenhouse filled with poinsettias and Christmas lights, Victorian glass architecture, soft fog, magical evening atmosphere",
  "Fantastical treehouse village decorated for Christmas, rope bridges with fairy lights, snow-dusted pine trees, warm lantern glow",
  
  // Traditional & Classic
  "Classic New England home exterior at Christmas, white picket fence, wreaths on windows, warm porch lights, fresh snow covering",
  "Traditional grand foyer with sweeping staircase, massive decorated Christmas tree, garlands on banisters, crystal chandelier, warm lighting",
  "Charming cottage kitchen decorated for Christmas, copper pots hanging, garlands of holly, warm light from vintage stove, snow visible through windows",
  
  // Artistic & Atmospheric
  "Art Deco-inspired Christmas scene, geometric patterns, gold and white decor, dramatic lighting, large arched windows with city view",
  "Japanese garden in winter with traditional elements, stone lanterns covered in snow, subtle Christmas lights, zen atmosphere",
  "Mediterranean villa courtyard at Christmas, string lights, potted citrus trees with lights, traditional decorations, warm evening glow"
];

export const enhancePrompt = (prompt: string) => {
  return `${prompt}. Photorealistic style, cinematic lighting, volumetric lighting, soft shadows, detailed textures, high resolution, 8k quality, professional photography, dramatic atmosphere, award-winning`;
};

export async function generateImage({ prompt, isPremium, width, height }: GenerateImageParams) {
  try {
    // Default dimensions if not provided
    const finalWidth = width || 1920;
    const finalHeight = height || 1080;
    
    // Ensure dimensions are multiples of 8 (SDXL requirement)
    const adjustedWidth = Math.floor(finalWidth / 8) * 8;
    const adjustedHeight = Math.floor(finalHeight / 8) * 8;

    // Get the current session
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw new Error('No authentication session found');
    }

    const remainingGenerations = await getRemainingGenerations(session.user.id);
    if (remainingGenerations <= 0) {
      throw new Error('No remaining generations available');
    }

    const response = await fetch('/api/generate-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.access_token}`
      },
      body: JSON.stringify({
        prompt: enhancePrompt(prompt.trim()),
        width: adjustedWidth,
        height: adjustedHeight,
        isPremium
      })
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `Failed to generate image: ${response.status}`);
    }

    // Decrement generations only after successful generation
    await decrementGenerations(session.user.id);

    return data.url;
  } catch (error) {
    console.error('Error generating image:', error);
    throw error instanceof Error ? error : new Error('Failed to generate image');
  }
}

export async function getRemainingGenerations(userId: string): Promise<number> {
  try {
    if (!userId) {
      console.error('getRemainingGenerations called with no userId');
      return 0;
    }

    const { data, error } = await supabase
      .from('profiles')
      .select('remaining_generations, is_premium')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching generations:', error);
      throw error;
    }

    if (!data) {
      console.error('No profile found for user:', userId);
      return 0;
    }

    return data.remaining_generations || 0;
  } catch (error) {
    console.error('Error getting remaining generations:', error);
    throw error;
  }
}

export async function decrementGenerations(userId: string): Promise<void> {
  try {
    const { error } = await supabase.rpc('decrement_generations', {
      user_id: userId
    });

    if (error) {
      console.error('Error decrementing generations:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error in decrementGenerations:', error);
    throw error;
  }
}