import { createContext, useContext, useEffect, useState, useRef } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import type { Database } from '../types/database';
import { handleError, showErrorToast } from '../utils/error';

type Profile = Database['public']['Tables']['profiles']['Row'];

interface AuthContextType {
  user: User | null;
  profile: Profile | null;
  loading: boolean;
  isPremium: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  refreshProfile: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const mounted = useRef(true);
  const isPremium = profile?.is_premium ?? false;

  const fetchProfile = async (userId: string): Promise<Profile | null> => {
    try {
      console.log('Fetching profile for user:', userId);
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) {
        // Only create profile if it doesn't exist
        if (error.code === 'PGRST116') {
          console.log('Profile not found, creating new profile');
          const { data: newProfile, error: createError } = await supabase
            .from('profiles')
            .upsert({
              id: userId,
              email: user?.email || '',
              remaining_generations: 5,
              total_generations: 0,
              is_premium: false,
              current_plan: 'free',
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString()
            })
            .select()
            .single();

          if (createError) throw createError;
          return newProfile;
        }
        throw error;
      }

      return data;
    } catch (err: any) {
      const apiError = handleError(err, 'fetchProfile');
      showErrorToast(apiError);
      return null;
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        console.log('Initializing auth...');
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) throw error;

        if (mounted.current) {
          if (session?.user) {
            setUser(session.user);
            const userProfile = await fetchProfile(session.user.id);
            if (mounted.current) {
              setProfile(userProfile);
            }
          } else {
            setUser(null);
            setProfile(null);
          }
          setLoading(false);
        }
      } catch (err: any) {
        console.error('Auth initialization error:', err);
        const apiError = handleError(err, 'initializeAuth');
        showErrorToast(apiError);
        if (mounted.current) {
          setUser(null);
          setProfile(null);
          setLoading(false);
        }
      }
    };

    initializeAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!mounted.current) return;

      console.log('Auth state changed:', event, session?.user?.id);
      
      try {
        if (session?.user) {
          setUser(session.user);
          const userProfile = await fetchProfile(session.user.id);
          if (mounted.current) {
            setProfile(userProfile);
          }
        } else {
          setUser(null);
          setProfile(null);
        }
      } catch (err) {
        console.error('Error handling auth state change:', err);
        if (mounted.current) {
          setUser(null);
          setProfile(null);
        }
      }
    });

    return () => {
      mounted.current = false;
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) {
        throw { ...error, code: `AUTH_${error.code}` };
      }
    } catch (err: any) {
      const apiError = handleError(err, 'signIn');
      showErrorToast(apiError);
      throw apiError;
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
          data: {
            email,
            remaining_generations: 5,
            is_premium: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }
        }
      });

      if (error) {
        throw { ...error, code: `AUTH_${error.code}` };
      }

      if (data.user) {
        // Wait for profile to be created by the trigger
        await new Promise(resolve => setTimeout(resolve, 1000));
        const userProfile = await fetchProfile(data.user.id);
        if (userProfile) {
          setProfile(userProfile);
        }
      }
    } catch (err: any) {
      const apiError = handleError(err, 'signUp');
      showErrorToast(apiError);
      throw apiError;
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw { ...error, code: `AUTH_${error.code}` };
      }
      setUser(null);
      setProfile(null);
    } catch (err: any) {
      const apiError = handleError(err, 'signOut');
      showErrorToast(apiError);
      throw apiError;
    }
  };

  const refreshProfile = async () => {
    if (!user) return;
    try {
      console.log('Manually refreshing profile for user:', user.id);
      
      // Fetch profile without the generated_images relation
      const { data: userProfile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching updated profile:', error);
        throw { ...error, code: `DB_${error.code}` };
      }

      if (!userProfile) {
        console.error('No profile found for user:', user.id);
        throw { message: 'Profile not found', code: 'PROFILE_NOT_FOUND', status: 404 };
      }

      if (mounted.current) {
        console.log('Updated profile:', {
          id: userProfile.id,
          remaining_generations: userProfile.remaining_generations,
          is_premium: userProfile.is_premium,
          current_plan: userProfile.current_plan
        });
        setProfile(userProfile);
      }
    } catch (err: any) {
      console.error('Error refreshing profile:', err);
      const apiError = handleError(err, 'refreshProfile');
      showErrorToast(apiError);
      throw apiError;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        profile,
        loading,
        isPremium,
        signIn,
        signUp,
        signOut,
        refreshProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}