import { GeneratedImage } from '../../types/database';
import { X, Download, Share2, Check, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

interface ImageModalProps {
  image: GeneratedImage;
  onClose: () => void;
}

export function ImageModal({ image, onClose }: ImageModalProps) {
  const [downloading, setDownloading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleDownload = async () => {
    if (downloading) return;
    
    setDownloading(true);
    try {
      const response = await fetch(image.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `christmas-ai-${image.id}.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.success('Image downloaded successfully!');
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Failed to download image');
    } finally {
      setDownloading(false);
    }
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(image.url);
      setCopied(true);
      toast.success('Image URL copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      toast.error('Failed to copy URL');
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ type: "spring", duration: 0.5 }}
          className="relative w-full max-w-4xl bg-white rounded-xl overflow-hidden shadow-2xl"
          onClick={e => e.stopPropagation()}
        >
          <div className="absolute top-4 right-4 z-10 flex items-center gap-2">
            <button
              onClick={handleDownload}
              className="p-2 bg-white/90 rounded-full text-gray-700 hover:text-gray-900 hover:bg-white transition-all duration-200 group"
              disabled={downloading}
            >
              {downloading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Download className="w-5 h-5 transition-transform group-hover:scale-110" />
              )}
            </button>
            <button
              onClick={handleShare}
              className="p-2 bg-white/90 rounded-full text-gray-700 hover:text-gray-900 hover:bg-white transition-all duration-200 group"
            >
              {copied ? (
                <Check className="w-5 h-5 text-green-600" />
              ) : (
                <Share2 className="w-5 h-5 transition-transform group-hover:scale-110" />
              )}
            </button>
            <button
              onClick={onClose}
              className="p-2 bg-white/90 rounded-full text-gray-700 hover:text-gray-900 hover:bg-white transition-all duration-200 group"
            >
              <X className="w-5 h-5 transition-transform group-hover:scale-110" />
            </button>
          </div>

          <div className="relative flex justify-center items-center min-h-[200px] max-h-[calc(90vh-200px)] bg-gray-50">
            {!imageLoaded && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
              </div>
            )}
            <img
              src={image.url}
              alt={image.prompt}
              onLoad={() => setImageLoaded(true)}
              className={`max-w-full max-h-[calc(90vh-200px)] object-contain ${imageLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200`}
            />
          </div>

          <div className="p-6 bg-white">
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900">Prompt</h3>
                <p className="mt-1 text-gray-600">{image.prompt}</p>
              </div>
              
              <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                <div className="text-sm text-gray-500">
                  Created on{' '}
                  {new Date(image.created_at).toLocaleDateString(undefined, {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </div>
                
                <div className="text-sm text-gray-500">
                  Model: {image.model || 'Standard'}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}