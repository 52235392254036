import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';

export default function AuthCallback() {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const resendConfirmation = async (email: string) => {
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: email
      });
      
      if (error) throw error;
      alert('A new confirmation email has been sent. Please check your inbox.');
    } catch (err) {
      console.error('Error resending confirmation:', err);
      alert('Failed to resend confirmation email. Please try signing up again.');
    }
  };

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get error information from URL if present
        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const error = hashParams.get('error');
        const errorDescription = hashParams.get('error_description');

        if (error) {
          console.error('Auth callback error:', { error, description: errorDescription });
          
          if (error === 'access_denied' && errorDescription?.includes('expired')) {
            // If link expired, show option to resend
            const email = localStorage.getItem('confirmEmail');
            if (email) {
              if (window.confirm('Your confirmation link has expired. Would you like to receive a new confirmation email?')) {
                await resendConfirmation(email);
              }
            }
            navigate('/login');
            return;
          }

          throw new Error(errorDescription || 'Authentication failed');
        }

        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw sessionError;
        }
        
        if (!session?.user) {
          console.error('No session found in callback');
          navigate('/login');
          return;
        }

        // The profile should be automatically created by the database trigger
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('id, email, remaining_generations')
          .eq('id', session.user.id)
          .single();

        if (profileError) {
          console.error('Error fetching profile:', profileError);
        } else {
          console.log('Profile verified:', profile);
        }

        // Clear stored email
        localStorage.removeItem('confirmEmail');
        
        // Redirect to home page
        navigate('/');
      } catch (error) {
        console.error('Error in auth callback:', error);
        setError(error instanceof Error ? error.message : 'Authentication failed');
        navigate('/login');
      }
    };

    handleCallback();
  }, [navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-lg font-medium text-red-600">Authentication Error</h2>
          <p className="mt-2 text-sm text-gray-500">{error}</p>
          <button
            onClick={() => navigate('/login')}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg"
          >
            Return to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
}
