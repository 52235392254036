import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import { useGenerations } from '../contexts/GenerationsContext';
import { Sparkles, Clock, LogOut, ChevronDown, ChevronUp, Gift, CreditCard } from 'lucide-react';

export const Profile: React.FC = () => {
  const { user, profile, loading: authLoading, signOut } = useAuth();
  const { remainingGenerations } = useGenerations();
  const navigate = useNavigate();
  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);

  // Only show loading state for initial auth load
  if (authLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Header />
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500"></div>
          </div>
        </main>
      </div>
    );
  }

  // Show error state if no user
  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Header />
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
          <div className="text-center">
            <h2 className="text-lg font-medium text-gray-900">Please sign in</h2>
            <p className="mt-2 text-sm text-gray-500">You need to be signed in to view your profile.</p>
            <button
              onClick={() => navigate('/login')}
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Back to Login
            </button>
          </div>
        </main>
      </div>
    );
  }

  // Show error state if no profile
  if (!profile) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Header />
        <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
          <div className="text-center">
            <h2 className="text-lg font-medium text-gray-900">No profile found</h2>
            <p className="mt-2 text-sm text-gray-500">Please try signing out and back in.</p>
            <button
              onClick={() => navigate('/login')}
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Back to Login
            </button>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header />
      <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
        <div className="space-y-6">
          {/* Profile Header */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
            <div className="flex items-center space-x-4">
              <div className="w-16 h-16 bg-gradient-to-br from-blue-500 to-blue-600 rounded-full flex items-center justify-center text-white text-2xl font-bold shadow-lg">
                {user.email?.[0].toUpperCase()}
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Your Profile</h1>
                <p className="text-gray-600">{user.email}</p>
              </div>
            </div>
          </div>

          {/* Account Type */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="p-2 bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg">
                  <Sparkles className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h2 className="text-xl font-semibold text-gray-900">
                    {profile.is_premium ? 'Premium Account' : 'Free Account'}
                  </h2>
                  <p className="text-gray-600">
                    {profile.is_premium ? 'Access to premium gallery and priority processing' : 'Basic features'}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                {!profile.is_premium && (
                  <button 
                    onClick={() => navigate('/pricing')}
                    className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all shadow-sm"
                  >
                    Upgrade →
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Generations Info */}
          <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Generations</h3>
            
            {/* Generations count */}
            <div className="mb-6">
              <div className="flex justify-between text-sm text-gray-600 mb-2">
                <span>Generations Remaining</span>
                <span>{remainingGenerations} available</span>
              </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg">
                <div className="flex items-center space-x-2 text-gray-600 mb-1">
                  <Gift className="w-4 h-4" />
                  <span className="text-sm">Total Generations</span>
                </div>
                <p className="text-2xl font-bold text-gray-900">
                  {profile.is_premium ? '30' : '5'}
                </p>
              </div>
              
              <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg">
                <div className="flex items-center space-x-2 text-gray-600 mb-1">
                  <Clock className="w-4 h-4" />
                  <span className="text-sm">Member Since</span>
                </div>
                <p className="text-2xl font-bold text-gray-900">
                  {new Date(profile.created_at).toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric'
                  })}
                </p>
              </div>
            </div>
          </div>

          {/* Purchase History */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
            <button
              onClick={() => setShowPurchaseHistory(!showPurchaseHistory)}
              className="w-full p-6 flex items-center justify-between text-left hover:bg-gray-50 transition-colors"
            >
              <div className="flex items-center space-x-3">
                <div className="p-2 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg">
                  <CreditCard className="w-6 h-6 text-gray-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900">Purchase History</h3>
              </div>
              {showPurchaseHistory ? (
                <ChevronUp className="w-5 h-5 text-gray-500" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-500" />
              )}
            </button>
            
            {showPurchaseHistory && (
              <div className="px-6 pb-6">
                <div className="border-t border-gray-100 pt-4">
                  {profile.is_premium ? (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between p-4 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg">
                        <div>
                          <p className="font-medium text-gray-900">Premium Gallery Access</p>
                          <p className="text-sm text-gray-600">Purchased on {new Date(profile.premium_since || profile.updated_at).toLocaleDateString()}</p>
                        </div>
                        <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">Active</span>
                      </div>
                    </div>
                  ) : (
                    <p className="text-gray-600 text-center py-4">No purchases yet</p>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Logout Button */}
          <div className="mt-8 flex justify-center">
            <button
              onClick={async () => {
                try {
                  await signOut();
                  navigate('/login');
                } catch (error) {
                  console.error('Error signing out:', error);
                }
              }}
              className="flex items-center space-x-2 px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
            >
              <LogOut className="w-5 h-5" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};