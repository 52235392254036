import { toast } from 'react-hot-toast';
import { ApiError } from '../types';

// Define error shapes we expect to handle
interface SupabaseError {
  code: string;
  message: string;
  details?: string;
}

interface ErrorWithCode {
  code: string;
  message?: string;
}

export function handleError(error: unknown, context: string): ApiError {
  console.error(`Error in ${context}:`, error);
  
  // Handle standard Error objects
  if (error instanceof Error) {
    return {
      message: error.message,
      code: 'GENERIC_ERROR',
      status: 500
    };
  }

  // Handle Supabase-style errors
  if (isSupabaseError(error)) {
    return {
      message: error.message,
      code: error.code,
      status: 400
    };
  }

  // Handle generic errors with code
  if (isErrorWithCode(error)) {
    return {
      message: error.message || 'An error occurred',
      code: error.code,
      status: 400
    };
  }

  // Handle string errors
  if (typeof error === 'string') {
    return {
      message: error,
      code: 'STRING_ERROR',
      status: 500
    };
  }

  // Default error
  return {
    message: 'An unexpected error occurred',
    code: 'UNKNOWN_ERROR',
    status: 500
  };
}

// Type guards
function isSupabaseError(error: unknown): error is SupabaseError {
  if (!error || typeof error !== 'object') return false;
  
  return (
    'code' in error &&
    'message' in error &&
    typeof (error as SupabaseError).code === 'string' &&
    typeof (error as SupabaseError).message === 'string'
  );
}

function isErrorWithCode(error: unknown): error is ErrorWithCode {
  if (!error || typeof error !== 'object') return false;
  
  return (
    'code' in error &&
    typeof (error as ErrorWithCode).code === 'string'
  );
}

export function showErrorToast(error: ApiError) {
  toast.error(error.message);
}

export function isAuthError(error: ApiError) {
  const authErrorCodes = ['UNAUTHORIZED', 'AUTH_ERROR', 'JWT_ERROR'];
  return authErrorCodes.includes(error.code) || error.status === 401;
}

export function isDatabaseError(error: ApiError) {
  return (
    error.code.startsWith('DB_') || 
    error.code.startsWith('PGRST') || 
    error.status === 400
  );
}

export function isNetworkError(error: ApiError) {
  return (
    error.code === 'NETWORK_ERROR' || 
    error.code === 'FETCH_ERROR' || 
    error.status === 503
  );
} 