import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

console.log('Application starting...');

const rootElement = document.getElementById('root');
console.log('Root element found:', !!rootElement);

if (!rootElement) {
  console.error('Root element not found');
  throw new Error('Failed to find the root element');
}

try {
  console.log('Creating root...');
  const root = createRoot(rootElement);
  
  console.log('Starting render...');
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  console.log('Render complete');
} catch (error) {
  console.error('Render error:', error);
  rootElement.innerHTML = `
    <div style="padding: 20px; text-align: center;">
      <h1 style="color: #ef4444;">Failed to initialize application</h1>
      <p>Please try refreshing the page. If the problem persists, contact support.</p>
      <pre style="margin-top: 20px; text-align: left; background: #f3f4f6; padding: 12px; border-radius: 6px; overflow: auto;">
        ${error instanceof Error ? `${error.message}\n${error.stack}` : String(error)}
      </pre>
    </div>
  `;
}
