import type { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Snowflake, TreePine } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';
import { Button } from './ui/Button';
import { useAuth } from '../contexts/AuthContext';

export const Header: FC = () => {
  const { isSnowEnabled, toggleSnow } = useTheme();
  const { user } = useAuth();
  const location = useLocation();

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2">
            <div className="relative">
              <TreePine className="w-8 h-8 text-green-600" />
              <div className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />
            </div>
            <span className="font-bold text-xl text-gray-900">
              ChristmasAI.art
            </span>
          </Link>

          {/* Navigation */}
          <nav className="flex items-center gap-4">
            {user && (
              <Link
                to="/generate"
                className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                  location.pathname === '/generate'
                    ? 'bg-red-100 text-red-600'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                Generate
              </Link>
            )}
            <Link
              to="/gallery"
              className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                location.pathname === '/gallery'
                  ? 'bg-red-100 text-red-600'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              Gallery
            </Link>
            <Link
              to="/pricing"
              className={`px-3 py-2 rounded-lg text-sm font-medium transition-colors ${
                location.pathname === '/pricing'
                  ? 'bg-red-100 text-red-600'
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              Pricing
            </Link>

            {/* Snow Toggle */}
            <Button
              onClick={toggleSnow}
              variant="outline"
              size="icon"
              className={`w-9 h-9 rounded-lg transition-colors ${
                isSnowEnabled 
                  ? 'bg-red-100 border-red-200 text-red-600 hover:bg-red-200'
                  : 'border-gray-200 text-gray-400 hover:text-gray-600 hover:border-gray-300'
              }`}
              title={isSnowEnabled ? 'Disable snow' : 'Enable snow'}
            >
              <Snowflake className="h-4 w-4" />
            </Button>

            {/* Auth Button */}
            {user ? (
              <Link
                to="/profile"
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  location.pathname === '/profile'
                    ? 'bg-red-600 text-white'
                    : 'bg-red-100 text-red-600 hover:bg-red-200'
                }`}
              >
                Profile
              </Link>
            ) : (
              <Link
                to="/login"
                className="px-4 py-2 rounded-lg text-sm font-medium bg-red-600 text-white hover:bg-red-700 transition-colors"
              >
                Get Started
              </Link>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};