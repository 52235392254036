import { createContext, useContext, useState } from 'react';

interface ThemeContextType {
  isSnowEnabled: boolean;
  toggleSnow: () => void;
}

const ThemeContext = createContext<ThemeContextType>({
  isSnowEnabled: true,
  toggleSnow: () => {},
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [isSnowEnabled, setIsSnowEnabled] = useState(true);

  const toggleSnow = () => {
    setIsSnowEnabled((prev: boolean) => !prev);
  };

  return (
    <ThemeContext.Provider value={{ isSnowEnabled, toggleSnow }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}; 