import { Header } from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import { Gift, Sparkle } from 'lucide-react';
import { PricingCard } from '../components/pricing/PricingCard';
import { STRIPE_PRICE_IDS, PRICES } from '../lib/constants';

const pricingPlans = [
  {
    name: PRICES.STARTER.name,
    description: 'Perfect for trying out our AI Christmas card generator',
    price: PRICES.STARTER.amount,
    stripePriceId: STRIPE_PRICE_IDS.STARTER,
    features: [
      { text: `${PRICES.STARTER.generations} AI Generations`, included: true },
      { text: 'Basic Card Styles', included: true },
      { text: 'Download Generated Images', included: true },
      { text: 'Share to Social Media', included: true },
      { text: 'Premium Card Styles', included: false },
      { text: 'Priority Generation', included: false },
    ],
  },
  {
    name: PRICES.HOLIDAY.name,
    description: 'For those who want to create multiple unique cards',
    price: PRICES.HOLIDAY.amount,
    stripePriceId: STRIPE_PRICE_IDS.HOLIDAY,
    features: [
      { text: `${PRICES.HOLIDAY.generations} AI Generations`, included: true },
      { text: 'All Card Styles', included: true },
      { text: 'Download Generated Images', included: true },
      { text: 'Share to Social Media', included: true },
      { text: 'Premium Card Styles', included: true },
      { text: 'Priority Generation', included: true },
    ],
  },
  {
    name: PRICES.PREMIUM.name,
    description: 'For businesses and power users',
    price: PRICES.PREMIUM.amount,
    stripePriceId: STRIPE_PRICE_IDS.PREMIUM,
    features: [
      { text: 'Unlimited Gallery Access', included: true },
      { text: 'All Card Styles', included: true },
      { text: 'Download Generated Images', included: true },
      { text: 'Share to Social Media', included: true },
      { text: 'Premium Card Styles', included: true },
      { text: 'Priority Generation', included: true },
    ],
  },
];

export function Pricing() {
  const { user, loading } = useAuth();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <Header />
      <div className="pt-16">
        <main className="py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            {/* Holiday Special Banner */}
            <div className="mb-12 p-6 rounded-2xl bg-gradient-to-r from-red-500 to-red-600 text-white shadow-lg">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <Gift className="w-8 h-8" />
                  <div>
                    <h3 className="text-lg font-semibold">Holiday Special Offer</h3>
                    <p className="text-red-100">Limited time pricing for the holiday season!</p>
                  </div>
                </div>
                <Sparkle className="w-6 h-6 animate-pulse" />
              </div>
            </div>

            {/* Pricing Header */}
            <div className="text-center mb-12">
              <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                Simple, transparent pricing
              </h1>
              <p className="mt-5 text-xl text-gray-500">
                Choose the perfect plan for your Christmas card creation needs
              </p>
              {!user && !loading && (
                <p className="mt-3 text-sm text-gray-500">
                  Sign up to get started with any plan
                </p>
              )}
            </div>

            {/* Pricing Cards */}
            <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
              {pricingPlans.map((plan, index) => (
                <PricingCard
                  key={plan.name}
                  plan={plan}
                  isPopular={index === 1} // Holiday bundle is popular
                />
              ))}
            </div>

            {/* FAQ Section */}
            <div className="mt-16 bg-white rounded-2xl shadow-sm p-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                Frequently Asked Questions
              </h2>
              <dl className="space-y-6">
                <div>
                  <dt className="text-lg font-semibold text-gray-900">
                    How do the generations work?
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    Each generation creates a unique AI-powered Christmas card or wallpaper. You can use your generations anytime during the holiday season.
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-semibold text-gray-900">
                    Can I upgrade my plan later?
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    Yes! You can upgrade to a higher plan at any time. Your remaining generations will be added to your new plan's allocation.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}